@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-header{
    background-image: url(./img/cesped.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-chart{
    background-image: url(./img/bg-footer.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-about{
    background-image: url(./img/portfolio/4.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-1{
    background-image: url(./img/bg-1.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-btn{
    background-image: url(./img/bg2.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-contact{
    background-image: url(./img/bg2.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-title{
    background-image: url(./img/bg-header.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.logo-icon{
    display: none;
}
a div img{
    max-height: 30px;
}

.left-icon:hover > a div img {
    display:flex;
}

.btn-phone {
    display: none;
    width: 70px;
    height: 70px;
    color: #fff;
    position: fixed;
    right: 80%;
    bottom: 20px;
    border-radius: 50%;
    line-height: 80px;
    text-align: center;
    z-index: 999;
  }

  .btn-whatsapp {
    display: block;
    width: 50px;
    height: 50px;
    color: #fff;
    position: fixed;
    right: 10px;
    bottom: 10px;
    border-radius: 50%;
    line-height: 80px;
    text-align: center;
    z-index: 999;
  }
  

  .header-title{
    background-image: url(./img/portfolio/25.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  @media screen and (max-width: 413px) {
    .btn-phone {
      display: block;
      width: 50px;
      height: 50px;
      color: #fff;
      position: fixed;
      right: 85%;
      bottom: 10px;
      border-radius: 50%;
      line-height: 80px;
      text-align: center;
      z-index: 999;
    }
  
  }
  